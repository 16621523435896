import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ib from "./ibdata";
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import CloseIcon from '@material-ui/icons/Close';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

var debounceTimer = null
var allData = {}
var allSites = {}
const maxCount = 5000
var calcMax = maxCount

export default function MyNotif(props) {
    const classes = useStyles();

    const open = props.open;
    function ExistingTable(p2) {
        const [myReq, setmyReq] = React.useState([])

        React.useEffect(() => {
            if (p2 && p2.tab) {
                let arr = []
                Object.keys(p2.tab.who).forEach(k => {
                    let o = p2.tab.who[k]
                    if (o.csid in allSites) {
                        let a = allSites[o.csid]
                        arr.push({ id: o.csid, count: o.count, name: a.name, img: a.Content.img, type: a.type, popular: a.popular })
                    }
                })
                setmyReq(arr)
            } else {
                setmyReq([])
            }
        }, [p2, p2.tab])
        
        const dtstr = p2 && p2.tab ? (p2.tab.count + " on " + p2.tab.date) : ""
        return (<MaterialTable
            title={dtstr}
            columns={[
                {
                    field: 'img',
                    title: 'Image',
                    render: rowData => <img src={rowData.img} style={{ width: 50, borderRadius: '5%' }} />
                },
                { title: 'Name', field: 'name' },
                { title: 'Count', field: 'count' },
                { title: 'Popular', field: 'popular', type: 'numeric', defaultSort: 'desc' },
            ]}
            options={{
                search: false
            }}

            data={myReq}

        />)
    }

    React.useEffect(() => {
        setupCalendarData()
    }, []);

    const handleClose = () => {
        if (props.cb)
            props.cb(null);
    };

    const [tabled, setTabled] = React.useState(null)

    const [cal, setCal] = React.useState({ startDate: new Date(), endDate: new Date("24-12-31"), data: [] })
    function setupCalendarData() {
        ib.listVacencys(null, gotData)
        allData = {}
        function gotData(dat) {
            if (!dat) return finishData()
            dat.forEach(d => {
                d.avail = JSON.parse(d.siteName)
                if (!(d.Campsite.id in allSites)) {
                    var f = JSON.parse(d.Campsite.content)
                    d.Campsite.Content = f
                    delete (d.Campsite.content)
                    allSites[d.Campsite.id] = d.Campsite
                }
                delete (d.siteName)
                allData[d.id] = d
            })
        }
    }
    function finishData() {
        var dateDict = {}
        calcMax = 0
        let lcSearch = state.search.length > 0 ? state.search.toLowerCase() : ""

        Object.keys(allData).forEach(a1 => {
            let a = allData[a1]

            if (state.siteType !== "all") {
                let allow = false
                if (a.id.includes(state.siteType)) {
                    allow = true
                }
                if (!allow) return
            }
            if (state.popularity !== "all") {
                let pp = parseInt(state.popularity)
                let allow = false
                if (a.Campsite.popular > pp) allow = true
                if (!allow) return
            }
            if (state.search.length > 0) {
                let allow = false
                let lowerName = a.Campsite.name.toLowerCase()
                if (lowerName.includes(lcSearch)) allow = true
                if (a.Campsite.id in allSites) {
                    let lowerKW = allSites[a.Campsite.id].Content.keywords.toLowerCase()
                    if (lowerKW.includes(lcSearch)) {
                        allow = true
                    }
                }
                if (!allow) return

            }
            a.avail.forEach(d => {

                if (!(d.date in dateDict)) {
                    dateDict[d.date] = { count: 0, date: d.date, who: {} }
                }
                dateDict[d.date].who[a.Campsite.id] = { count: d.count, csid: a.Campsite.id }
                dateDict[d.date].count += d.count
                if (dateDict[d.date].count > calcMax) {
                    calcMax = dateDict[d.date].count
                    if (calcMax > maxCount) calcMax = maxCount
                }
            })
        })
        var all = []
        Object.keys(dateDict).forEach(a1 => {
            let a = dateDict[a1]
            all.push(a)
        })
        setCal(p => ({
            ...p,
            data: all
        }))
        if (tabled) {
            var old = tabled.date
            all.forEach(a => {
                if (a.date === old) {
                    setTabled(a)
                }
            })
        }
    }
    function clickOn(value) {
        setTabled(value)
    }
    const [state, setState] = React.useState({
        siteType: 'all',
        popularity: 'all',
        search: ""
    });


    function applyFilter() {

        if (debounceTimer !== null) {
            clearTimeout(debounceTimer);
            debounceTimer = null;
        }
        debounceTimer = setTimeout(function () {
            // console.log("Setting color to = ", col);
            finishData()
            clearTimeout(debounceTimer);
        }, 400);
    }


    React.useEffect(() => {
        applyFilter()
    }, [state])
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };
    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Campsite Availability
            </DialogTitle>
            <CloseIcon style={{
                backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%", position: "absolute", right: "35px", top: "18px",
            }} onClick={handleClose} />
            <DialogContent>
                <>
                    <DialogContentText>

                        <Grid container direction="row">

                            <Grid item style={{ width: "100%", backgroundColor: "#ebbf23", color: "#000000" }}>

                                <Grid container justify="center" alignItems="center" >
                                    <Grid item>
                                        <FormControl className={classes.formControl}>
                                            <NativeSelect
                                                value={state.siteType}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'siteType',
                                                    id: 'age-native-helper',
                                                }}
                                            >
                                                <option value={"all"}>All</option>
                                                <option value={"Rec"}>National Park</option>
                                                <option value={"RESV_"}>State Park</option>
                                                <option value={"SON"}>Sonoma County</option>

                                            </NativeSelect>
                                            <FormHelperText>Campground type</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl className={classes.formControl}>
                                            <NativeSelect
                                                value={state.popularity}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'popularity',
                                                    id: 'age-native-helper',
                                                }}
                                            >
                                                <option value={"all"}>All</option>
                                                <option value={"90"}>Very Popular</option>
                                                <option value={"30"}>Medium</option>
                                                <option value={"1"}>Low</option>

                                            </NativeSelect>
                                            <FormHelperText>Campground Popularity</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>

                                        <TextField id="outlined-search" name="search" value={state.search} onChange={handleChange} type="search" />
                                        <FormHelperText>Search by name</FormHelperText>

                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                                <CalendarHeatmap
                                    startDate={cal.startDate}
                                    endDate={cal.endDate}
                                    values={cal.data}
                                    classForValue={value => {
                                        if (!value) {
                                            return 'color-empty';
                                        }
                                        // console.log("CHECK ", value) 
                                        const vals = [0, calcMax * 0.10, calcMax * 0.40, calcMax * 0.70, calcMax * 0.9]
                                        for (let i = 0; i < vals.length; i++) {
                                            if (value.count < vals[i]) {
                                                let v = `color-github-${i}`
                                                return v;
                                            }
                                        }
                                        return `color-github-4`;
                                    }}
                                    tooltipDataAttrs={value => {
                                        if (!value.count) {
                                            return {
                                                'data-tip': `Available Sites: 0`,
                                            };
                                        }
                                        return {
                                            'data-tip': `Available Sites: ${value.count}`,
                                        };
                                    }}
                                    showWeekdayLabels={true}
                                    onClick={value => clickOn(value)}
                                />
                            </Grid>
                            <Grid item style={{ width: "100%" }} >
                                {tabled && <ExistingTable tab={tabled} />}
                            </Grid>
                        </Grid>
                    </DialogContentText>

                </>
            </DialogContent>
            <ReactTooltip />
        </Dialog >
    );
}
