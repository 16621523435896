import React from 'react';
import * as ib from "./ibdata";
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function GetSites(props) {
    var [array, setArray] = React.useState([])
    function getRec(data, full) {
        var d = new Date();
        var n = d.getMonth() + 1;
        n = n + ""
        var m = n.padStart(2, '0');
        URL = "https://www.recreation.gov/api/camps/availability/campground/" + data + "/month?start_date=2021-" + m + "-01T00%3A00%3A00.000Z"
        ib.getCamps(URL, 'GET', null, done)
        function done(data) {
            if (!data || !data['campsites']) return
            let ff = data['campsites']
            var arr2 = []
            for (let f in ff) {
                let rr = ff[f]
                let save = { site: rr.site, name: rr.loop + ":" + rr.site, full: full }
                arr2.push(save)
            }
            setArray(arr => ([...arr, ...arr2]))

        }

    }

    function getCalif(data2, full) {
        Date.isLeapYear = function (year) { 
            return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
        };
        
        Date.getDaysInMonth = function (year, month) {
            return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
        };
        
        Date.prototype.isLeapYear = function () { 
            return Date.isLeapYear(this.getFullYear()); 
        };
        
        Date.prototype.getDaysInMonth = function () { 
            return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
        };
        
        Date.prototype.addMonths = function (value) {
            var n = this.getDate();
            this.setDate(1);
            this.setMonth(this.getMonth() + value);
            this.setDate(Math.min(n, this.getDaysInMonth()));
            return this;
        };

        function formatDate(d) {
            var month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [month, day, year].join('-');
        }


        let p = new Date()
        var d = new Date();
        var nm = d.addMonths(1);
        var n = formatDate(p);
        var n1 = formatDate(nm);

        let data = {
            "FacilityId": data2, "UnitTypeId": 0, "StartDate": n, "EndDate": nm, "InSeasonOnly": true, "WebOnly": true, "IsADA": false,
            "SleepingUnitId": "75", "UnitCategoryId": "1", "UnitTypesGroupIds": [], "MinDate": "11/13/2024", "MaxDate": "12/31/2025"
        }
        let str = "https://calirdr.usedirect.com/rdr/rdr/search/grid";
        ib.getCamps(str, 'POST', JSON.stringify(data), done)
        function done(data2) {
            if (!data2 || !data2['Facility']) return
            let ff = data2['Facility']
            let units = ff['Units']
            var arr2 = []
            for (let f in units) {
                let rr = units[f]
                let save = { site: rr.ShortName, name: ff.Description + ":" + rr.ShortName, full: full }
                arr2.push(save)
            }
            setArray(arr => ([...arr, ...arr2]))

        }

    }
    React.useEffect(() => {
        if (props.sites) {
            props.sites.forEach(e => {
                if (e.id.includes("Rec_")) {
                    let r = e.id.replace("Rec_", "");
                    getRec(r, e.id)
                }
                if (e.id.includes("RESV_")) {
                    let r = e.id.replace("RESV_", "");
                    getCalif(r, e.id)
                }
            });
        }
    }, [props.sites])

    function MultiSelect(data) {
        props.cb(data)
    }

    return (
        <div>
            <MaterialTable
                title="Pick Sites and click +"
                data={array}
                columns={[
                    { title: 'Site Name', field: 'name' },
                ]}
                options={{
                    selection: true
                }}
                actions={[
                    {
                        tooltip: 'Add',
                        icon: "addcircle",
                        onClick: (evt, data) => MultiSelect(data)
                    }
                ]}
                components={{
                    Action:
                        props => {
                            if (props.action.icon === 'addcircle') {
                                return (
                                    <Button
                                        onClick={(event) => props.action.onClick(event, props.data)}
                                        color="secondary"
                                        variant="contained"
                                        style={{ textTransform: 'none' }}
                                        size="small"
                                        startIcon={<AddCircleIcon />}
                                    >
                                        Add Sites
                                    </Button>
                                )
                            }
                        }
        
                }}
            />
        </div>
    )
}