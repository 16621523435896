import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ib from "./ibdata";
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MaterialTable from 'material-table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import GetSites from './GetSites';
const dow = [
    "Su", "M", "Tu", "W", "Th", "F", "Sa"
]
export default function MyNotif(props) {
    const open = props.open;
    const [text, setText] = React.useState("");
    const [myReq, setmyReq] = React.useState([])

    function MultiSelect(data) {

        for (let j = 0; j < data.length; j++) {
            var n = data[j]
            ib.deleteCampsiteRequest(n)
        }
    }
    function ExistingTable(p2) {
        return (<MaterialTable
            title="Existing Requests"
            columns={[
                {
                    field: 'img',
                    title: 'Image',
                    render: rowData => <img src={rowData.img} style={{ width: 50, borderRadius: '5%' }} />
                },
                { title: 'Name', field: 'name' },
                { title: 'Nights', field: 'nights' },

                { title: 'Campsite', field: 'campName' },
                { title: 'Start Date', field: 'sdate' },
                { title: 'End Date', field: 'edate' },

            ]}
            options={{
                selection: true
            }}
            data={myReq}
            actions={[
                {
                    tooltip: 'Delete requests',
                    icon: "delete",
                    onClick: (evt, data) => MultiSelect(data)
                }
            ]}

        />)
    }
    const [nights, setNights] = React.useState("1");

    function handleNights(e) {
        let r = e.target.value
        setNights(r)
    }
    React.useEffect(() => {
        var s1, s2;
        ib.subscribeCampRequests(gotData, delcb);

        function delcb(n) {
            setmyReq(list => list.filter(item => item.id !== n.id));
        }
        function gotData(dat) {
            let ff = [...myReq];
            for (let j = 0; j < dat.length; j++) {
                var n = dat[j]
                for (var i = 0; i < ff.length; i++) {
                    var c = ff[i]
                    if (c.id === n.id) {
                        setmyReq(list => list.filter(item => item.id !== n.id));
                    }
                }
                try {
                    let ff = JSON.parse(n.Campsite.content);
                    n.campName = n.Campsite.name
                    n.img = ff.img
                    n.sdate = n.startdate.split('T')[0]
                    n.edate = n.enddate.split('T')[0]

                } catch { }
                setmyReq(list => [...list, n]);

            }
            //            setmyReq(ff);
        }
        return () => {
            if (s1) s1.unsubscribe();
            if (s2) s2.unsubscribe();

        }
    }, []);

    const [inData, setInData] = React.useState(props.data ? props.data : null)
    React.useEffect(() => {
        setInData(props.data)
    }, [props.data])
    const handleClose = () => {
        if (props.cb)
            props.cb(null);
    };
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const maxDate = new Date('December 31, 2025 23:24:00');
    const [allSites, setAllSites] = React.useState([])

    const handleDateChange = (date) => {
        if (new Date(date) > maxDate) {
            alert("Please pick a date before December. 31 2025")
            return
        }
        setSelectedDate(date);
    };
    const [selectedEDate, setSelectedEDate] = React.useState(addDays(new Date(), 14));

    const handleDateChangeE = (date) => {
        if (new Date(date) > maxDate) {
            alert("Please pick a date before  December. 31 2025")
            return
        }
        setSelectedEDate(date);
    };
    const [phone, setPhone] = React.useState("");

    function handleOnChange(ph) {
        setPhone(ph);
    }
    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    }
    function handleSave(e) {
        if (!text) return
        if (!phone) {
            alert("Please enter phone number");
            return
        }

        for (let i = 0; i < props.data.length; i++) {
            const d = props.data[i]
            var s = []
            if (allSites.length > 0) {
                for (let j = 0; j < allSites.length; j++) {
                    let pp = allSites[j]
                    if (pp.full === d.id) {
                        s.push(pp)
                    }
                }
            }
            var str = ""
            if (state.pickDow) {
                var st = new Date(selectedDate)
                var ed = new Date(selectedEDate)
                for (let dt = st; dt <= ed; dt = addDays(dt, 1)) {
                    let dw = dt.getDay()
                    if (dowState[dow[dw]]) {
                        var todayDate = new Date(dt - (dt.getTimezoneOffset() * 60000))
                            .toISOString()
                            .split("T")[0];
                        str += todayDate + ","
                    }
                }
            }
            let dt1 = new Date(selectedDate.setHours(0, 0, 0, 0));
            let dt2 = new Date(selectedEDate.setHours(0, 0, 0, 0));

            var req = {
                phone: phone,
                name: text,
                CampsiteID: d.id,
                startdate: dt1,
                enddate: dt2,
                loadBucket: d.loadBucket,
                nights: nights
            }
            if (str.length > 0) {
                req['dates'] = str
            }
            if (s.length > 0) {
                req['content'] = JSON.stringify({
                    'filterSite': s
                })
            }
            ib.createCampsiteRequest(req);
        }
        setInData(null);
        // if (props.cb)
        //     props.cb(null);
    }

    function handleLoad(e) {
        setText(e.target.value)
    }
    const [state, setState] = React.useState({
        pickSite: false,
        pickDow: false,
    })

    const [tableOpen, setTableOpen] = React.useState({ open: false, cb: doneTable })
    const handlePickSite = (event) => {
        let c = event.target.checked
        if (c) {
            setTableOpen({ open: true, cb: doneTable })
        }
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    function handleDaysofWeek(event) {
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    const [dowState, setDowState] = React.useState({
        "Su": false,
        "M": false,
        "Tu": false,
        "W": false,
        "Th": false,
        "F": false,
        "Sa": false,
    })
    function handleDay(event) {
        setDowState({ ...dowState, [event.target.name]: event.target.checked });

    }

    const [tabled, setTabled] = React.useState("")

    function doneTable(data) {
        setTableOpen({ open: false, cb: doneTable })
        let s = ""
        data.forEach(e => {
            s += e.site + ","
        })
        setTabled(s);
        setAllSites(data);
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {inData ? "Create New Request" : "Campsite Requests"}

            </DialogTitle>
            <CloseIcon style={{
                backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%", position: "absolute", right: "35px", top: "18px",
            }} onClick={handleClose} />
            <DialogContent>
                {myReq.length > 0 && !inData && !tableOpen.open && (
                    <ExistingTable />

                )}

                {inData && (
                    <>
                        <form onSubmit={handleSave}>
                            <DialogContentText>

                                <Typography variant="h6" align="center" component="h4" gutterBottom>

                                    {props.data.map((d) => (
                                        <li>
                                            {d.name}
                                        </li>
                                    ))}
                                </Typography>

                            </DialogContentText>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={6}>

                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Start Date"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>

                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="End Date"
                                            value={selectedEDate}
                                            onChange={handleDateChangeE}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MuiPhoneNumber
                                            label="Phone Number to send text"
                                            defaultCountry={'us'}
                                            required
                                            onChange={handleOnChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="nomal"
                                            id="name"
                                            label="Label"
                                            type="string"
                                            required
                                            value={text}
                                            onChange={handleLoad}

                                        />
                                    </Grid>

                                    <Grid item xs={6}>

                                        <FormControlLabel
                                            control={<Checkbox checked={state.pickSite} onChange={handlePickSite} name="pickSite" />}
                                            label="Only specific sites"
                                        />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="nomal"
                                            id="nights"
                                            label="Number of Nights"
                                            type="number"
                                            value={nights}
                                            onChange={handleNights}

                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={state.pickDow} onChange={handleDaysofWeek} name="pickDow" />}
                                            label="Pick days of the week"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        {state.pickSite && (
                                            <>
                                                {tableOpen.open ? (
                                                    <GetSites sites={inData} {...tableOpen} />

                                                ) : (

                                                    <TextField
                                                        margin="nomal"
                                                        id="name"
                                                        label="Sites"
                                                        type="string"
                                                        inputProps={{
                                                            readOnly: true
                                                        }}

                                                        value={tabled}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>

                                    <Grid item xs={6}>
                                        {state.pickDow && (
                                            <>
                                                {dow.map((d) => (
                                                    <FormControlLabel
                                                        control={<Checkbox checked={dowState[d]} onChange={handleDay} name={d} />}
                                                        label={d}
                                                    />
                                                ))}

                                            </>
                                        )}
                                    </Grid>

                                </Grid>
                            </MuiPickersUtilsProvider>

                            <Button onClick={handleSave} variant="contained"
                                type="submit" color="primary">
                                Save

                            </Button>
                            <div className='termsDiv'>
                            <Checkbox />

                                I agree to get campsite notification by text and the Policies at  <a href="/terms">Terms</a> and <a href="/Privacy">Privacy</a>. My phone carrier may charge me for these messages. The frequency of the messages depends on the sites I register to be notified about. Reply STOP to stop or delete the campaign.
                            </div>
                        </form>

                    </>
                )}
            </DialogContent>

        </Dialog >
    );
}
